import {
  Getusertrail_FETCH_STARTED,
  Getusertrail_FETCH_SUCCESS,
  Getusertrail_FETCH_ERROR
} from '../actions/getUsertrial';


 


const defaultState = {
 trials: [],
  loading: false,
};

function getUsertrialReducer(state = defaultState, action) {
  switch (action.type) {
    case Getusertrail_FETCH_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    case Getusertrail_FETCH_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        trials: action.payload.data,
      });
    case Getusertrail_FETCH_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        trials: [],
      });
    default:
      return state;
  }
}

export default getUsertrialReducer;
