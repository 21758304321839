import { Button } from "reactstrap";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import actions from "../../../redux/actions/stripepricedata";
import { connect, useDispatch } from "react-redux";
import usertrailActions from "../../../redux/actions/getUsertrial";
import moment from "moment";
import { Link } from "react-router-dom";


function StripePayment(props) {
  const [productdata, setproductsdata] = useState([]);
  const [customer_id, setCustomerid] = useState("");
  const [mode, setMode] = useState("subscription");
  const [client_id, setclientId] = useState("");
  const [trail, setTrail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalopen, setisModalopen] = useState(false);
  // const [price_id, setprice_id] = useState("price_1OtTzPBw4PJJ3ODnHRIeGTQ6");
  // const [product_id, setProductId] = useState("prod_PivrWXHdmch3Gu");
  // const [promo_id, setPromoId] = useState("promo_1OtU2jBw4PJJ3ODnWkbdIRpO");
  const [price_id, setprice_id] = useState("price_1Ow0rdBw4PJJ3ODn4jccQ85Z");
  const [product_id, setProductId] = useState("prod_PlXxiS0vV7JdcC");
  const [promo_id, setPromoId] = useState("promo_1Ow17dBw4PJJ3ODnpL0RDUBU");

  const [selectedOption, setSelectedOption] = useState({
    value: "",
    label: "",
  });

  // const [tips, setTips] = useState({
  //   value: "10",
  //   day: "Weekday",
  // });

  // const [tips2, setTips2] = useState({
  //   value: "15",
  //   day: "Weekday",
  // });

  // const [tips3, setTips3] = useState({
  //   value: "20",
  //   day: "Weekday",
  // });

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(actions.getProductslist()).then((res) => {
      res?.data.sort(
        (a, b) => a.default_price.unit_amount - b.default_price.unit_amount
      );

      setproductsdata(res?.data);
    });
  }, []);

  useEffect(() => {
    if (props?.currentUser) {
      setCustomerid(props?.currentUser?.customer_id);
      setclientId(props?.currentUser?.id);
        dispatch(
          usertrailActions.getusertrail({ client_id: props?.currentUser?.id })
        )
        .then((res) => {
          setTrail(res?.data);
        });
    }
  }, [props.currentUser]);

  // const handlechangedata = (event) => {
  //   setTips({
  //     value: event.target.value,
  //     day: event.target.getAttribute("day"),
  //   });
  //   setprice_id(event.target.getAttribute("price_id"));
  //   setMode(event.target.getAttribute("mode"));

  //   setSelectedOption({
  //     value: event.target.value,
  //     label: event.target.nextSibling.textContent,
  //   });

  //   setProductId(event.target.getAttribute("productid"));
  // };

  // const handlechangedata2 = (event) => {
  //   setTips2({
  //     value: event.target.value,
  //     day: event.target.getAttribute("day"),
  //   });
  //   setprice_id(event.target.getAttribute("price_id"));
  //   setMode(event.target.getAttribute("mode"));

  //   setSelectedOption({
  //     value: event.target.value,
  //     label: event.target.nextSibling.textContent,
  //   });
  //   setProductId(event.target.getAttribute("productid"));
  // };

  // const handlechangedata3 = (event) => {
  //   setTips3({
  //     value: event.target.value,
  //     day: event.target.getAttribute("day"),
  //   });
  //   setprice_id(event.target.getAttribute("price_id"));
  //   setMode(event.target.getAttribute("mode"));

  //   setSelectedOption({
  //     value: event.target.value,
  //     label: event.target.nextSibling.textContent,
  //   });
  //   setProductId(event.target.getAttribute("productid"));
  // };

  const handlePay = () => {
    if (props.isLoggedIn) {
      if (trail?.length > 0) {
        setisModalopen(true);
      } else {
        if (price_id) {
          setLoading(true);
          dispatch(
              actions.SubscriptionData({
                price_id: price_id,
                customer_id: customer_id,
                mode: mode,
                client_id: client_id,
                pricename: selectedOption.label,
                product_id: product_id,
                // promo_id: promo_id,
              })
            )
            .then((res) => {
              setLoading(false);
              window.location.href = res.url;
            });
        } else {
          toast.error("Please select a plan first");
        }
      }
    } else {
      toast.error("Please login first");
    }
  };

  return (
    <div>
      <div>
              <h3 style={{
                  fontFamily: "Poppins",
                  // color: "white",
                  marginBottom: 8,
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: 8,
                }}>
              YOUR PTP RATINGS SUBSCRIPTION {moment().isAfter(moment(props.currentUser.exp)) ? "EXPIRED" : "EXPIRES"} ON {moment(props.currentUser.exp).format("DD-MM-YYYY")}
              </h3>
              {" "}
              {/* <p
                style={{
                  fontSize: 14,
                  fontFamily: "Poppins",
                  // color: "white",
                  marginBottom: 8,
                  lineHeight: 1.4,
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: 8,
                }}
              >
                SUBSCRIBE TO PTP RATINGS BEFORE 10 APRIL 2024 TO RECEIVE 50% OFF PTP RATINGS SUBSCRIPTION FOR 6 MONTHS.
                <br /> $120 PER MONTH
                <br /> 50% OFF $60 PER MONTH
                <br /> ENTER PROMO CODE <span style={{color: "#44BD32"}}>PTP2024</span> TO RECEIVE DISCOUNT
                <br /> IF YOU WISH TO PAY VIA BANK TRANSFER PLEASE <Link to={`/contactus`}>CONTACT US</Link> AND WE WILL SEND YOU BANK DETAILS AND REFERENCE NUMBER 
              </p> */}
              <p
                style={{
                  fontSize: 14,
                  fontFamily: "Poppins",
                  // color: "white",
                  marginBottom: 8,
                  lineHeight: 1.4,
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: 8,
                }}
              >Please subscribe to view the PTP Ratings </p>
            </div>
    <Button onClick={handlePay} color="danger">
      SUBSCRIBE NOW
    </Button>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  stripepricedata: state.stripepricedata.Productslist,
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StripePayment);
